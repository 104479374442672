var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "announcement-link",
        attrs: { href: _vm.stripeCustUrl, target: "_blank" },
      },
      [_vm._v(_vm._s(this.params.data.stripeCustId))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }